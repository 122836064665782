import { searchParamsHelper } from '@utils/searchParamsHelper';
import localforage from 'localforage';
import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

import { ALL_FILTERS } from '@/mocks/filters';
import { FavoriteData, Filter } from '@/shared/types/common';

class FavoriteStore {
  items: FavoriteData[] = [];
  filters: Map<string, Filter> = new Map();

  constructor() {
    makeAutoObservable(this);
    makePersistable(
      this,
      {
        name: 'favorite',
        properties: ['items'],
        storage: typeof window !== 'undefined' ? localforage : undefined,
        expireIn: 86400000, // One day in milliseconds
        removeOnExpiration: true,
        stringify: false,
        debugMode: true,
      },
      { delay: 200, fireImmediately: false },
    );
  }

  add(newItem: FavoriteData) {
    const alreadyExists = this.items.some(
      item => item.realName === newItem.realName,
    );
    if (!alreadyExists) {
      this.items = [newItem, ...this.items];
    }
  }

  delete(realName: string) {
    this.items = this.items.filter(
      currentItem => currentItem.realName !== realName,
    );
  }

  clearItems() {
    this.items = [];
  }

  getFavoriteNames() {
    return this.items.map(item => item.realName);
  }

  toggleFilter(filter: Filter) {
    const filterKey = filter.filter;
    const currentFilter = this.filters.get(filterKey);
    if (currentFilter) {
      this.filters.delete(filterKey);
    } else {
      const foundFilter = ALL_FILTERS.find(f => f.filter === filterKey);
      if (foundFilter) {
        this.filters.set(foundFilter.filter, foundFilter);
      }
    }
  }

  resetFilters() {
    this.filters = new Map();
  }

  get getFilters() {
    return this.filters;
  }

  setInitialFilters(params: Partial<Record<keyof typeof this, any>>) {
    for (let key of Object.values(params).flat()) {
      const currentFilter = ALL_FILTERS.find(item => item.filter === key);
      currentFilter &&
        !this.filters.has(key) &&
        this.filters.set(key, currentFilter);
    }
  }

  get getFiltersAsSearchParams() {
    const result: Record<string, string[]> = {};
    const arrayOfFilters = Array.from(this.filters);

    for (const [key, value] of arrayOfFilters) {
      if (!result[value.group]) {
        result[value.group] = [value.filter];
      } else {
        result[value.group].push(value.filter);
      }
    }
    return {
      params: result,
      paramsAsString: searchParamsHelper(result),
    };
  }
}
export const favoriteStore = new FavoriteStore();
