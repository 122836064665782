import { CartItemsResponseData } from '@hooks/queries/common.types';
import localforage from 'localforage';
import { makeAutoObservable } from 'mobx';
import {
  hydrateStore,
  isHydrated,
  isPersisting,
  makePersistable,
} from 'mobx-persist-store';

import { AvailablePaymentMethods } from '@/app/[locale]/cart/types';
import { PaymentMethodIllustrations } from '@/shared/ui/illustration/illustration';

export type CartItem = {
  id: number;
  price: number;
  count: number;
  selected: boolean;
  prices: number[];
};

export type CartCheckoutInfo = {
  isTermsAccepted: boolean;
  isPaymentValid: boolean;
  activePaymentMethod: keyof PaymentMethodIllustrations | null;
  promocode: string;
  promocodePercentValue: string | number;
  availablePaymentMethods: AvailablePaymentMethods[];
  accountInfo: {
    isUsingAccountBalance: boolean;
    accountBalance: number;
  };
  totalSum: number;
};

const mockedAvailablePaymentMethods: AvailablePaymentMethods[] = [
  { tagValue: 3, paymentMethod: 'etherium' },
  { tagValue: 3, paymentMethod: 'visa' },
  { tagValue: 3, paymentMethod: 'u-money' },
  { tagValue: 3, paymentMethod: 'tether' },
  { tagValue: 3, paymentMethod: 'alfa' },
  { tagValue: 3, paymentMethod: 'sber-pay' },
  { tagValue: 3, paymentMethod: 'bitcoin' },
  { tagValue: 3, paymentMethod: 'tron' },
  { tagValue: 3, paymentMethod: 'payok' },
  { tagValue: 3, paymentMethod: 'qiwi' },
  { tagValue: 3, paymentMethod: 'game-money' },
  { tagValue: 3, paymentMethod: 'web-pay' },

  { tagValue: 3, paymentMethod: 'codi' },
  { tagValue: 3, paymentMethod: 'mexico-local-stores' },
  { tagValue: 3, paymentMethod: 'mexico-online-banking' },
  { tagValue: 3, paymentMethod: 'nu-pay' },
  { tagValue: 3, paymentMethod: 'oxxo' },
  { tagValue: 3, paymentMethod: 'pix' },
  { tagValue: 3, paymentMethod: 'spei' },
];

class CartStore {
  cart: CartItemsResponseData = {
    summary: {
      count: 0,
      totalPrice: 0,
      discount: 0,
      promocode: 0,
      totalPayPrice: 0,
    },
    isAllSelected: false,
    list: [],
  };
  notAvailableName: string | null = null;
  success: boolean | null = null;
  checkoutInfo: CartCheckoutInfo = {
    availablePaymentMethods: mockedAvailablePaymentMethods,
    isTermsAccepted: false,
    isPaymentValid: true,
    activePaymentMethod: null,
    promocode: '',
    promocodePercentValue: 5,
    accountInfo: {
      isUsingAccountBalance: false,
      accountBalance: 23,
    },
    totalSum: 0,
  };

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'cartStore',
      properties: ['cart'],
      storage: typeof window !== 'undefined' ? localforage : undefined,
      expireIn: 86400000,
      removeOnExpiration: true,
      stringify: false,
      debugMode: true,
    }).then(() => {
      this.calculateSummary();
    });
  }

  calculateSummary() {
    this.cart.summary = this.cart.list.reduce(
      (acc, item) => {
        if (item.selected) {
          const validPriceCount = Math.min(item.prices.length, item.count);
          const totalPriceForItem = item.prices
            .slice(0, validPriceCount)
            .reduce((sum, price) => sum + Number(price), 0);

          acc.count += item.count;
          acc.totalPrice += totalPriceForItem;
        }
        return acc;
      },
      {
        count: 0,
        totalPrice: 0,
        discount: 0,
        promocode: 0,
        totalPayPrice: 0,
      },
    );

    this.cart.summary.totalPayPrice =
      this.cart.summary.totalPrice - this.cart.summary.discount;
    this.calculateAllSelected();
  }

  addToCart(item: CartItem) {
    const maxCount = item.prices.length;

    if (item.count > maxCount) {
      item.count = maxCount;
    }

    const existingItemIndex = this.cart.list.findIndex(
      cartItem => cartItem.id.toString() == item.id.toString(),
    );

    if (existingItemIndex !== -1) {
      this.cart.list[existingItemIndex] = item;
    } else {
      this.cart.list = [item, ...this.cart.list];
    }
    this.calculateSummary();
    return item;
  }

  clearCart() {
    this.cart = {
      summary: {
        count: 0,
        totalPrice: 0,
        discount: 0,
        promocode: 0,
        totalPayPrice: 0,
      },
      isAllSelected: false,
      list: [],
    };
    this.calculateSummary();
  }

  deleteAvailableItem(itemId: number) {
    this.cart.list = this.cart.list.filter(
      item => item.id.toString() !== itemId.toString(),
    );
    this.calculateSummary();
  }

  deleteUnavailableItem(itemId: number) {
    this.cart.list = this.cart.list.filter(item => {
      if (item.id === itemId) {
        if (item.count > item.prices.length) {
          item.count = item.prices.length;
        }
        return item.prices.length > 0;
      }
      return true;
    });
  }

  removeSoldout() {
    this.cart.list = this.cart.list.filter(item => {
      if (item.count > item.prices.length) {
        item.count = item.prices.length;
      }
      return item.prices.length > 0;
    });
  }

  toggleSelected(itemId: number) {
    this.cart.list = this.cart.list.map(item =>
      item.id.toString() === itemId.toString()
        ? { ...item, selected: !item.selected }
        : item,
    );
    this.calculateSummary();
  }

  removeAllSelected() {
    this.cart.list = this.cart.list.filter(item => !item.selected);
    this.calculateSummary();
  }

  toggleAllSelected(allSelected: boolean) {
    const newState = !allSelected;
    this.cart.list = this.cart.list.map(item => ({
      ...item,
      selected: newState,
    }));
    this.cart.isAllSelected = newState;
    this.calculateSummary();
  }

  calculateAllSelected() {
    this.cart.isAllSelected = this.cart.list.every(item => item.selected);
  }
  setPromocode(value: string) {
    this.checkoutInfo.promocode = value;
  }

  toggleTermsAccepted() {
    this.checkoutInfo.isTermsAccepted = !this.checkoutInfo.isTermsAccepted;
    if (this.checkoutInfo.isTermsAccepted) {
      this.checkoutInfo.isPaymentValid = true;
    }
  }

  toggleUsingAccountBalance() {
    this.checkoutInfo.accountInfo.isUsingAccountBalance =
      !this.checkoutInfo.accountInfo.isUsingAccountBalance;
  }

  setPaymentMethod(paymentMethod: CartCheckoutInfo['activePaymentMethod']) {
    this.checkoutInfo.activePaymentMethod = paymentMethod;
  }

  validateTermsAndPayment() {
    if (!this.checkoutInfo.isTermsAccepted) {
      this.checkoutInfo.isPaymentValid = false;
    }
  }

  setNotAvailable(name: string | null) {
    this.notAvailableName = name;
  }

  setSuccess(newState: boolean | null) {
    this.success = newState;
  }

  async hydrate(data: any): Promise<void> {
    await hydrateStore(data);
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get isPersisting() {
    return isPersisting(this);
  }
}

export const cartStore = new CartStore();
