import { ADOPT, Currency, ENGLISH, Game, Locale, USD } from '@utils/AppConfig';
import localforage from 'localforage';
import { makeAutoObservable, toJS } from 'mobx';
import { isHydrated, makePersistable } from 'mobx-persist-store';

type HistoryItem = { step: number; path: string; params?: string; url: string };

class AppStore {
  selectedLanguage: Locale = ENGLISH;
  selectedCurrency: Currency = USD;
  selectedGame: Game = ADOPT;
  currenHistoryStep: number = 0;
  navigationHistory: HistoryItem[] = [];
  constructor() {
    makeAutoObservable(this);
    makePersistable(
      this,
      {
        name: 'appStore',
        properties: ['selectedCurrency', 'selectedLanguage', 'selectedGame'],
        storage: typeof window !== 'undefined' ? localforage : undefined,
        expireIn: 86400000, // One day in milliseconds
        removeOnExpiration: true,
        stringify: false,
        debugMode: true,
      },
      { delay: 200, fireImmediately: false },
    ).then(r => {
      //console.log('persist',r);
    });
  }

  pushNavigationHistory(pathname: string, params?: string) {
    if (pathname === `/${this.selectedLanguage}`) {
      this.resetNavigationHistory();
    }
    if (this.navigationHistory.length === 0) {
      this.navigationHistory = [
        {
          step: 1,
          path: `/${this.selectedLanguage}`,
          url: `/${this.selectedLanguage}`,
        },
      ];
      this.currenHistoryStep = 1;
    }

    if (
      this.lastVisitedPage.path !== pathname &&
      !this.navigationHistory.find(
        item => item.path === pathname && item.params === params,
      )
    ) {
      this.navigationHistory = [
        ...this.navigationHistory,
        {
          step:
            this.navigationHistory[this.navigationHistory.length - 1].step + 1,
          path: pathname,
          params,
          url: `${pathname}${params ? '?' + params : ''}`,
        },
      ];
      this.currenHistoryStep =
        this.navigationHistory[this.navigationHistory.length - 1].step + 1;
    } else {
      this.navigationHistory = this.navigationHistory.map((item, idx) =>
        idx === this.navigationHistory.length - 1
          ? { ...item, params, url: `${pathname}${params ? '?' + params : ''}` }
          : item,
      );
    }
  }

  resetNavigationHistory() {
    this.navigationHistory = [];
    this.currenHistoryStep = 0;
  }

  get lastVisitedPage() {
    return this.navigationHistory[this.navigationHistory.length - 1];
  }

  get prevPage() {
    const item = this.navigationHistory.find(
      item => item.step === this.currenHistoryStep - 2,
    );

    if (item) {
      this.currenHistoryStep = this.currenHistoryStep - 1;
      const newHistory: HistoryItem[] = [];
      this.navigationHistory.map(item => {
        if (item.step <= this.currenHistoryStep) {
          newHistory.push(item);
        }
      });
      this.navigationHistory = newHistory;
      return item;
    } else {
      this.currenHistoryStep = 1;
      return {
        step: 1,
        path: `/${this.selectedLanguage}`,
        url: `/${this.selectedLanguage}`,
      };
    }
  }

  setCurrency(curr: Currency) {
    this.selectedCurrency = curr;
  }

  setGame(game: Game) {
    this.selectedGame = game;
  }

  setLanguage(lang: Locale) {
    this.selectedLanguage = lang;
  }

  get getLanguage() {
    return this.selectedLanguage;
  }

  get getGame() {
    return this.selectedGame;
  }

  get getCurrency() {
    return isHydrated(this) ? this.selectedCurrency : USD;
  }

  get getCurrencySign() {
    const currencyMap = {
      usd: '$',
      eur: '€',
    } as const;

    return currencyMap[this.selectedCurrency];
  }

  get isHydrated() {
    return isHydrated(this);
  }
}

export const appStore = new AppStore();
